var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AdminTable',{attrs:{"id":"admin-market-csgo-item","columns":_vm.columns,"title":`Коллекция market-csgo-items`,"model-name":"marketCsgoItemModel"},on:{"update:selected":value => (_vm.selectedItems = value)},scopedSlots:_vm._u([{key:"top-right-bots",fn:function({ query, search }){return [_c('div',{staticClass:"market-grid"},[_c('q-select',{attrs:{"options":[null, 'DEFAULT', 'EXPOSED', 'GIVEN', 'SOLD'],"outlined":"","rounded":"","dense":"","label":"Статус предмета","option-label":value => (value ? value : 'Любой'),"display-value":_vm.selectedConditionState ? _vm.selectedConditionState : 'Любой'},on:{"input":function($event){return search(_vm.searchCondition)}},model:{value:(_vm.selectedConditionState),callback:function ($$v) {_vm.selectedConditionState=$$v},expression:"selectedConditionState"}}),_c('ApolloMutation',{attrs:{"tag":"span","mutation":gql => gql`
            mutation updateMarketCsgoInventory($steamBotId: ID!) {
              updateMarketCsgoInventory(steamBotId: $steamBotId)
            }
          `},on:{"error":error =>
            _vm.$q.notify({
              message: error.message,
              multiLine: true,
              type: 'negative'
            }),"done":() => {
            _vm.$q.notify({
              message: `Успех`,
              multiLine: true,
              type: 'positive'
            });
            query.refetch();
          }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [_c('q-btn',{staticClass:"full-width",attrs:{"label":"Обновить инвентарь","color":"warning","disable":!_vm.selectedSteamBot,"push":"","size":"md","rounded":"","loading":loading},on:{"click":function($event){return mutate({
                variables: { steamBotId: _vm.selectedSteamBot._id }
              })}}})]}}],null,true)}),_c('ApolloMutation',{attrs:{"tag":"span","mutation":gql => gql`
            mutation removeAllMarketCsgoItems($steamBotId: ID!) {
              removeAllMarketCsgoItems(steamBotId: $steamBotId)
            }
          `},on:{"error":error =>
            _vm.$q.notify({
              message: error.message,
              multiLine: true,
              type: 'negative'
            }),"done":() => {
            _vm.$q.notify({
              message: `Успех`,
              multiLine: true,
              type: 'positive'
            });
            query.refetch();
          }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [_c('q-btn',{attrs:{"label":"Снять все с продажи","color":"negative","disable":!_vm.selectedSteamBot,"push":"","size":"md","rounded":"","loading":loading},on:{"click":function($event){return mutate({
                variables: { steamBotId: _vm.selectedSteamBot._id }
              })}}})]}}],null,true)}),_c('q-select',{style:(_vm.$q.screen.gt.sm ? 'min-width: 200px;' : 'min-width: 100px;'),attrs:{"outlined":"","rounded":"","dense":"","loading":!!_vm.selectedState,"label":"Установить статус","options":[null, 'DEFAULT', 'EXPOSED', 'GIVEN', 'SOLD'],"disable":!_vm.selectedItems.length},on:{"input":value => (value ? _vm.changeSelectedItemsState() : undefined)},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}}),_c('q-select',{style:(_vm.$q.screen.gt.sm ? 'min-width: 200px;' : 'min-width: 100px;'),attrs:{"outlined":"","rounded":"","dense":"","loading":_vm.setPriceLoading,"label":"Отношение цены","use-input":"","fill-input":"","display-value":null,"value":_vm.priceRelation,"options":[0.8, 0.85, 0.9, 1]},on:{"input-value":value => (_vm.priceRelation = value ? Number(value) : null)}}),_c('q-btn',{attrs:{"loading":_vm.setPriceLoading,"label":"Установить цену","push":"","size":"md","rounded":"","color":"primary","disable":!_vm.selectedItems.length ||
            !/^\d+(\.\d+)?$/.test(String(_vm.priceRelation))},on:{"click":function($event){return _vm.setPrice(false)}}}),_c('q-btn',{attrs:{"loading":_vm.setPriceLoading,"label":"Установить авто-понижение","push":"","size":"md","rounded":"","color":"warning","disable":!_vm.selectedItems.length ||
            !/^\d+(\.\d+)?$/.test(String(_vm.priceRelation))},on:{"click":function($event){return _vm.setPrice(true)}}}),_c('q-btn',{attrs:{"loading":_vm.incrementPriceByPercentLoading,"label":"Понизить цену (1%)","push":"","size":"md","rounded":"","color":"primary","disable":!_vm.selectedItems.length},on:{"click":function($event){return _vm.incrementPriceByPercent(-0.01)}}}),_c('ApolloQuery',{attrs:{"tag":"span","query":gql => gql`
            query getMarketCsgoItemsSettings {
              getMarketCsgoItemsSettings {
                autoPriceReductionPercentage
              }
            }
          `},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data } }){return [_c('ApolloMutation',{attrs:{"tag":"span","mutation":gql => gql`
                mutation setMarketCsgoItemsSettings(
                  $key: String!
                  $value: JSON!
                ) {
                  setMarketCsgoItemsSettings(key: $key, value: $value)
                }
              `},on:{"error":error =>
                _vm.$q.notify({
                  message: error.message,
                  multiLine: true,
                  type: 'negative'
                }),"done":() => {
                _vm.$q.notify({
                  message: `Успех`,
                  multiLine: true,
                  type: 'positive'
                });
              }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [_c('q-input',{attrs:{"value":data && data.getMarketCsgoItemsSettings
                    ? data.getMarketCsgoItemsSettings
                        .autoPriceReductionPercentage
                    : 0,"outlined":"","rounded":"","dense":"","label":"% понижения 12 часов","loading":loading},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (({ target: { value } }) => {
                    mutate({
                      variables: {
                        value,
                        key: 'autoPriceReductionPercentage'
                      }
                    });
                    if (!data.getMarketCsgoItemsSettings) {
                      data.getMarketCsgoItemsSettings = {};
                    }
                    data.getMarketCsgoItemsSettings.autoPriceReductionPercentage = value;
                  }).apply(null, arguments)}}})]}}],null,true)})]}}],null,true)}),_c('AdminSteamBotSelector',{attrs:{"type":'SELL',"value":_vm.selectedSteamBot},on:{"update:value":[value => {
            _vm.selectedSteamBot = value;
            search(_vm.searchCondition);
          },function($event){_vm.selectedSteamBot=$event}]}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }