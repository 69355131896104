
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";
import AdminSteamBotSelector from "@/components/admin/AdminSteamBotSelector.vue";
import gql from "graphql-tag";

@Component({
  components: {
    AdminTable,
    AdminSteamBotSelector
  }
})
export default class AdminMarketCsgoItem extends Vue {
  selectedSteamBot: any = null;
  selectedItems: any = [];
  selectedState: string | null = null;
  selectedConditionState: string | null = null;
  priceRelation: number | null = null;
  setPriceLoading = false;
  incrementPriceByPercentLoading = false;

  get searchCondition() {
    const condition: any = {};
    if (this.selectedSteamBot) {
      condition.steamBotId = this.selectedSteamBot._id;
    }

    if (this.selectedConditionState) {
      condition.state = this.selectedConditionState;
    }

    return Object.keys(condition).length ? condition : null;
  }

  async setPrice(priceReductionUntil = false) {
    if (this.priceRelation && !Number.isNaN(this.priceRelation)) {
      this.setPriceLoading = true;
      for await (const item of this.selectedItems) {
        try {
          const price = Number(
            (this.priceRelation * item.steamItem.price).toFixed(2)
          );
          let updatedFields: any = { price };

          if (priceReductionUntil) {
            updatedFields = { priceReductionUntil: price };
          }
          await this.updateModelDoc(item._id, updatedFields);

          if (priceReductionUntil) {
            item.priceReductionUntil = price;
          } else {
            item.price = price;
          }
        } catch (e) {
          this.$q.notify({
            type: `negative`,
            message: `${item._id}: ${e.message}`
          });
        }
      }

      this.setPriceLoading = false;
    }
  }

  async incrementPriceByPercent(percent = -0.01) {
    this.incrementPriceByPercentLoading = true;
    for await (const item of this.selectedItems) {
      try {
        const relation = item.price / item.steamItem.price + percent;
        const price = Number((relation * item.steamItem.price).toFixed(2));
        const updatedFields: any = { price };

        await this.updateModelDoc(item._id, updatedFields);

        item.price = price;
      } catch (e) {
        this.$q.notify({
          type: `negative`,
          message: `${item._id}: ${e.message}`
        });
      }
    }

    this.incrementPriceByPercentLoading = false;
  }

  async updateModelDoc(_id: string, updatedFields: any, unsetFields?: any) {
    return this.$apollo.mutate({
      mutation: gql`
        mutation updateModelDoc(
          $_id: ID!
          $updatedFields: JSON!
          $unsetFields: JSON
          $modelName: String!
        ) {
          updateModelDoc(
            _id: $_id
            updatedFields: $updatedFields
            modelName: $modelName
            unsetFields: $unsetFields
          )
        }
      `,
      // Parameters
      variables: {
        _id,
        updatedFields,
        unsetFields,
        modelName: "marketCsgoItemModel"
      }
    });
  }

  async changeSelectedItemsState() {
    for await (const item of this.selectedItems) {
      if (item.state !== "DEFAULT") {
        continue;
      }

      try {
        await this.updateModelDoc(item._id, { state: this.selectedState });
        item.state = this.selectedState;
      } catch (e) {
        this.$q.notify({
          type: `negative`,
          message: `${item._id}: ${e.message}`
        });
      }
    }

    this.selectedState = null;
  }

  columns = [
    {
      name: "_id",
      required: true,
      label: "_id",
      field: "_id",
      classes: "ellipsis",
      style: "max-width: 15px",
      align: "left",
      type: "string"
    },
    // {
    //   name: "assetId",
    //   align: "center",
    //   label: "assetId",
    //   field: "assetId",
    //   editable: true,
    //   type: "string"
    // },
    // {
    //   name: "instanceId",
    //   align: "center",
    //   label: "instanceId",
    //   field: "instanceId",
    //   editable: true,
    //   type: "string"
    // },
    // {
    //   name: "classId",
    //   align: "center",
    //   label: "classId",
    //   field: "classId",
    //   editable: true,
    //   type: "string"
    // },
    {
      name: "steamBot",
      align: "center",
      label: "Бот",
      field: "steamBot",
      type: "string",
      format: (value, row) =>
        value
          ? `${value._id} / ${value.username}`
          : `${row.steamBotId} / бот удален`
    },
    {
      name: "steamItemId",
      align: "center",
      label: "Скин",
      field: "steamItemId",
      editable: true,
      type: "string"
    },

    {
      name: "state",
      align: "center",
      label: "Статус",
      field: "state",
      editable: true,
      sortable: true,
      type: "string",
      enum: [`DEFAULT`, `EXPOSED`, `GIVEN`, `SOLD`]
    },
    {
      name: "priceReductionUntil",
      align: "center",
      label: "Авто-понижение до",
      sortable: true,
      field: "priceReductionUntil",
      editable: true,
      type: "number"
    },
    {
      name: "price",
      align: "center",
      label: "Цена",
      sortable: true,
      field: "price",
      editable: true,
      type: "number"
    },
    {
      name: "safePrice",
      align: "center",
      sortable: true,
      label: "Safe price",
      field: "steamItem",
      format: value => (value ? value.price : 0),
      type: "number"
    },
    {
      name: "relation",
      align: "center",
      label: "Процент",
      format: (val, row) =>
        (row.price
          ? Number(
              (
                (row.price / (row.steamItem ? row.steamItem.price : 1)) *
                100
              ).toFixed(2)
            )
          : 0) + "%",
      type: "string",
      classes: "text-negative text-weight-bold"
    },
    {
      name: "position",
      field: "position",
      align: "center",
      label: "Позиция",
      sortable: true,
      type: "number",
      classes: "text-positive text-weight-bold"
    },
    {
      name: "unstable",
      align: "center",
      sortable: true,
      label: "unstable",
      field: "steamItem",
      format: value => (value ? value.unstable : true),
      type: "string"
    },
    {
      name: "createdAt",
      align: "right",
      label: "createdAt",
      field: "createdAt",
      sortable: true,
      type: "string"
    },
    {
      name: "updatedAt",
      align: "right",
      label: "updatedAt",
      field: "updatedAt",
      sortable: true,
      type: "string"
    }
  ];
}
