
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AdminSteamBotSelector extends Vue {
  @Prop() type: string | undefined;
  @Prop() enabled: boolean | undefined;
  @Prop() value: any | undefined;
  getSteamBotsSmallInfo: any = [];
  options: any = [];

  onResult({ data }) {
    if (data && data.getSteamBotsSmallInfo) {
      this.getSteamBotsSmallInfo = data.getSteamBotsSmallInfo;
      this.options = [null, ...data.getSteamBotsSmallInfo];
    }
  }

  filterOptions(val, update, abort) {
    if (!val) {
      update(() => {
        this.options = [null, ...this.getSteamBotsSmallInfo];
      });
    } else {
      update(() => {
        const needle = val.toLowerCase();
        this.options = this.getSteamBotsSmallInfo.filter(
          ({ _id }) => _id.toLowerCase().indexOf(needle) > -1
        );
      });
    }
  }
}
