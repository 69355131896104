var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ApolloQuery',{attrs:{"query":gql => gql`
      query getSteamBotsSmallInfo($type: SteamBotType, $enabled: Boolean) {
        getSteamBotsSmallInfo(type: $type, enabled: $enabled)
      }
    `,"id":"admin-steam-bot-selector","tag":"span","fetch-policy":"no-cache","variables":{ type: _vm.type, enabled: _vm.enabled }},on:{"result":_vm.onResult},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data } }){return [(data && data.getSteamBotsSmallInfo)?_c('q-select',{attrs:{"options":_vm.options,"label":"Бот","outlined":"","rounded":"","dense":"","value":_vm.value,"use-input":"","option-label":option =>
          option
            ? `${option._id}
            / ${option.username}
            / ${option.steamItemsCount}
            / ${option.steamItemsPrice} steam
            / ${option.marketCsgoBalance} market
            / ${_vm.$options.filters.moment(option.createdAt, `DD-MM-YY`)}`
            : `Все`,"display-value":_vm.value ? _vm.value._id : `Все`},on:{"filter":_vm.filterOptions,"input":val => _vm.$emit('update:value', val)}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }