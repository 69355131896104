import { render, staticRenderFns } from "./AdminSteamBotSelector.vue?vue&type=template&id=c70ddb6c&scoped=true&"
import script from "./AdminSteamBotSelector.vue?vue&type=script&lang=ts&"
export * from "./AdminSteamBotSelector.vue?vue&type=script&lang=ts&"
import style0 from "./AdminSteamBotSelector.vue?vue&type=style&index=0&id=c70ddb6c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c70ddb6c",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect});
